import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../modules/shared-components/header/header-controller";
import Footer from "../modules/shared-components/footer/footer-controller";

import { useSelector } from "react-redux";
import SEO from "../modules/shared-components/seo";
import Navigation from "../modules/shared-components/navigation";
import { getLocaleValue } from "../helpers/get-locale-value";
import { getMetaDetails } from "../helpers/get-meta-details";

const useStyles = makeStyles({
  ahTitle: {
    textAlign: "center",
    fontSize: "3em",
    fontWeight: "bold",
    color: "#BBB",
    maxWidth: "100%",
    marginBottom: "30px",
  },
  title: {
    fontSize: "1.2em",
  },
  description: {
    textAlign: "center",
    fontSize: "0.9em",
  },
  goHomeLink: {
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "center",
    fontWeight: "bold",
    textDecoration: "underline",
  },
});

const NotFoundPage = ({ location, pageContext }) => {
  const {
    header,
    footer,
    siteSetting,
    reloadRequired,
    meta_details_variants,
  } = pageContext;
  const classes = useStyles();

  const locale = useSelector(state => {
    return state.localeReducer.locale;
  }, []);

  const seoData = getMetaDetails(
    locale,
    meta_details_variants,
    siteSetting,
    siteSetting
  );

  return (
    <>
      <SEO siteSetting={seoData} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="100vh"
      >
        <Header
          header={header}
          location={location}
          siteSetting={siteSetting}
          reloadRequired={reloadRequired}
        />

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding={"20px 40px"}
        >
          <Box className={classes.ahTitle}>Ahhhhhhhhhhhh!</Box>
          <Box mb={"10px"} className={classes.title}>
            This page doesn't exist
          </Box>
          <Box className={classes.description}>
            <p>
              Remember those old 404 pages from the 90s that said something like
              "Something's gone wrong, but don't worry our webmasters have been
              notified".
            </p>
            <p>
              But were the webmaster ever notified? I mean, were they really?
            </p>
          </Box>
          <Navigation to={`/`} className={classes.goHomeLink}>
            Let's go to a page which is working correctly, ok?
          </Navigation>
        </Box>
        <Footer footer={footer} siteSetting={siteSetting} />
      </Box>
    </>
  );
};

export default NotFoundPage;
